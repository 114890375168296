.main {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(0.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .card {
    background-color: #f0f0f0;
    padding: 1rem;
    border-radius: 1rem;
    height: 80%;
    width: 80%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.825;
      border-radius: 1rem;
      z-index: 1;
      pointer-events: none;
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-shadow: 0 0 2rem rgba(255, 255, 255, 0.2);
    }

    & > div {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #f0f0f0;
      display: flex;
      align-items: center;
      justify-content: center;

      & > .closeIcon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 1.3rem;
        cursor: pointer;
        padding: 0 1rem;
      }
    }
  }
}